import { useTranslation } from "react-i18next";
import { Button } from "../../../../components";

const GenerateShipmentFooter = ({ onCancel, onSave, isSaveDisabled, primaryButtonLabel = 'Primary Action' }) => {
    const { i18n } = useTranslation()
    return (
        <footer className="flex flex-col justify-center gap-4 w-full h-full p-4">
            <div className="flex justify-end h-12 gap-4">
                <Button
                    type="secondary"
                    onClick={onCancel}
                    className="min-w-1/4"
                >
                    <span className="text-xl">{i18n.t('generate_shared_label.cancel')}</span>
                </Button>

                <Button
                    disabled={isSaveDisabled}
                    onClick={onSave}
                    className="min-w-1/4"
                >
                    <span className="text-xl">{primaryButtonLabel}</span>
                </Button>
            </div>
        </footer>
    )
}

export default GenerateShipmentFooter
