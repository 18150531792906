import { CheckCircleTwoTone } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ProductSearch from './ProductSearch';

const ProductListHeader = ({pendingItemsToBeReviewed, productSearch, productSearchRef, onSearchChange = (value) => {}, productSearchDependencies, productSearchAlertMsg}) => {
    const { i18n } = useTranslation()

    return (
        <div className='flex justify-between'>
            <div>
                <div className='flex p-1 gap-2'>
                    <h2 className='mb-0 text-2xl font-bold'>{i18n.t('generate_shipment.product_list.required_products')}</h2>

                    {pendingItemsToBeReviewed === 0 && (
                        <div className='flex flex-col justify-center'>
                            <CheckCircleTwoTone
                                style={{fontSize: '20px'}}
                                twoToneColor="#52c41a"
                            />
                        </div>
                    )}
                </div>

                {pendingItemsToBeReviewed > 0 && <span className='mb-0 text-lg'>{i18n.t('generate_shipment.product_list.scan_all_items')}</span>}
                {pendingItemsToBeReviewed === 0 && <span className='mb-0 text-lg text-green-400 font-medium'>{i18n.t('generate_shipment.product_list.order_complete')}</span>}
            </div>

            <ProductSearch
                search={productSearch}
                inputRef={productSearchRef}
                onSearchChange={onSearchChange}
                dependencies={productSearchDependencies}
                alertMsg={productSearchAlertMsg}
            />
        </div>
    )
}

export default ProductListHeader
