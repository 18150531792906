import Select from 'react-select'
import { useTranslation } from 'react-i18next';

import { InputField } from "../../../../../components";

const AddressData = ({shippingAddress, onShippingAddressChange, isEditDisabled, countyOptions}) => {
    const { i18n } = useTranslation()

    return (
        <>
            <InputField
                title={i18n.t('generate_shipment.shipping_address.address')}
                value={shippingAddress.address}
                onChange={newValue => onShippingAddressChange({...shippingAddress, address: newValue})}
                disabled={isEditDisabled}
                className="w-full"
            />

            <InputField
                title={i18n.t('generate_shipment.shipping_address.address_2')}
                value={shippingAddress.address2}
                onChange={newValue => onShippingAddressChange({...shippingAddress, address2: newValue})}
                disabled={isEditDisabled}
                className="w-full"
            />

            <div className="flex gap-4">
                <div className="w-2/4">
                    <span className='text-sm font-medium text-gray-700'>{i18n.t('generate_shipment.shipping_address.country')}</span>

                    <Select
                        options={countyOptions}
                        isDisabled={isEditDisabled}
                        value={shippingAddress.country}
                        onChange={newValue => onShippingAddressChange({...shippingAddress, country: newValue})}
                    />
                </div>

                <InputField
                    title={i18n.t('generate_shipment.shipping_address.city')}
                    value={shippingAddress.city}
                    onChange={newValue => onShippingAddressChange({...shippingAddress, city: newValue})}
                    disabled={isEditDisabled}
                    className="w-2/4"
                />
            </div>

            <div className="flex gap-4">
                <InputField
                    title={i18n.t('generate_shipment.shipping_address.province')}
                    value={shippingAddress.province}
                    onChange={newValue => onShippingAddressChange({...shippingAddress, province: newValue})}
                    disabled={isEditDisabled}
                    className="w-2/4"
                />

                <InputField
                    title={i18n.t('generate_shipment.shipping_address.zip_code')}
                    value={shippingAddress.zipCode}
                    onChange={newValue => onShippingAddressChange({...shippingAddress, zipCode: newValue})}
                    disabled={isEditDisabled}
                    className="w-2/4"
                />
            </div>
        </>
    )
}

export default AddressData
