import { useTranslation } from "react-i18next";

import { InputField } from "../../../../../components";

const ContactData = ({shippingAddress, onShippingAddressChange, isEditDisabled}) => {
    const { i18n } = useTranslation()

    return (
        <div className="flex gap-4">
            <InputField
                title={i18n.t('generate_shipment.shipping_address.phone')}
                value={shippingAddress.phone}
                onChange={newValue => onShippingAddressChange({...shippingAddress, phone: newValue})}
                disabled={isEditDisabled}
                className="w-2/4"
            />
            <InputField
                title={i18n.t('generate_shipment.shipping_address.email')}
                value={shippingAddress.email}
                onChange={newValue => onShippingAddressChange({...shippingAddress, email: newValue})}
                disabled={isEditDisabled}
                className="w-2/4"
            />
        </div>
    )
}

export default ContactData
