import { PageTopBar, PageView, Button } from "../../../components";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SearchInput } from "../../../components/SearchInput"
import { GENERATE_ORDER_LABEL, GENERATE_SHARED_LABEL } from "../../../navigation/constants"
import { fetchPickingOrderWithToteCode, fetchSipSiblingByProduct } from "../../../services/pickingOrderServices";
import { stopRecording } from '../../../redux/webcamRecorderSlice';
const grouped_orders_default_warehouse = 100 // default only for warehouse 4 in Mexico
export const GenerateShipmentView = () => {
    const { i18n } = useTranslation()
    const dispatch = useDispatch()
    const { warehouse_id } = useParams()
    const history = useHistory()
    const inputRefTote = useRef()
    const inputRefProduct = useRef()
    const [toteSearchIsLoading, setToteSearchIsLoading] = useState(false)
    const [ToteNotFound, setToteNotFound] = useState(false)
    const [messageToteNotFound, setMessageToteNotFound] = useState(false)
    const [showProductScanInput, setShowProductScanInput] = useState(false)
    const [scannedTotePickingOrderId, setScannedTotePickingOrderId] = useState(null)

    const focusToteInput = () => {
        const focusTimer = setTimeout(() => {
            inputRefTote.current.focus()
        }, 50)
        return () => clearTimeout(focusTimer)
    }
    const focusProductInput = () => {
        const focusTimer = setTimeout(() => {
            inputRefProduct.current.focus()
        }, 50)
        return () => clearTimeout(focusTimer)
    }

    useEffect(focusToteInput, [])
    useEffect(() => {
        if (showProductScanInput) {
            return focusProductInput()
        }
        return focusToteInput()
    }, [showProductScanInput])

    const goToGenerateLabel = (orderId) => {
        history.push(GENERATE_ORDER_LABEL.replace(":id", orderId).replace(':warehouse_id', warehouse_id))
    }

    const handleSearchChange = async (value) => {
        dispatch(stopRecording())

        setToteNotFound(false)
        setShowProductScanInput(false)
        let pickingOrder = null
        let noValido = /\s/
        if (noValido.test(value) || value === "") {
            return
        }
        setToteSearchIsLoading(true)
        try {
            let sortBy = [
                { field: 'order_packing_status' }
            ]
            pickingOrder = await fetchPickingOrderWithToteCode(value, warehouse_id, sortBy)
        } catch (error) {
            if (error.response.status === 404) {
                setToteNotFound(true)
                setMessageToteNotFound(i18n.t('generate_shipment.view.tote_not_found'))
                setToteSearchIsLoading(false)
                return
            }
            console.log(error)
            setToteSearchIsLoading(false)
            window.alert(i18n.t('generate_shipment.view.unexpected_error'))
            return
        }
        let order = pickingOrder.order
        // console.log("pickingOrder: ", pickingOrder)
        // console.log("order: ", order)
        if (pickingOrder.in_review) {
            setToteNotFound(true)
            setMessageToteNotFound(i18n.t('generate_shipment.view.order_in_review'))
            setToteSearchIsLoading(false)
            return
        }
        if (order.status !== 'packing') {
            setToteNotFound(true)
            setMessageToteNotFound(i18n.t('generate_shipment.view.order_not_packing'))
            setToteSearchIsLoading(false)
            return
        }

        setToteSearchIsLoading(false)
        if (pickingOrder.single_item_picking) {
            setScannedTotePickingOrderId(pickingOrder.id)
            setShowProductScanInput(true)
        }
        else {
            goToGenerateLabel(order.id)
        }
    }

    const handleProductSearchChange = async (productSkuUpc) => {
        if (productSkuUpc === " " || productSkuUpc === "") {
            return
        }
        setToteSearchIsLoading(true)
        setToteNotFound(false)
        let pickingOrder = null
        try {
            pickingOrder = await fetchSipSiblingByProduct(scannedTotePickingOrderId, productSkuUpc)
            // console.log("pickingOrder: ", pickingOrder)
            goToGenerateLabel(pickingOrder.order.id)
        } catch (error) {
            setToteNotFound(true)
            setMessageToteNotFound(i18n.t('generate_shipment.view.order_not_found'))
        }
        setToteSearchIsLoading(false)
    }

    return (
        <PageView
            topMenu={<PageTopBar>
                <div className="text-lg font-semibold"><span className="text-gray-400">{i18n.t('generate_shipment.view.generate_label_title')}</span> </div>
                {warehouse_id == grouped_orders_default_warehouse &&

                    <Button className="whitespace-nowrap mr-2" onClick={() => {
                        history.push(GENERATE_SHARED_LABEL.replace(':warehouse_id', warehouse_id))
                    }
                    }>
                        {i18n.t('generate_shipment.view.generate_label_button')}
                    </Button>
                }
            </PageTopBar>}
            childrenFullWidth={true}
            topMenuFullWidth={true}
        >
            <div className="flex-1 flex items-stretch overflow-hidden">
                <main className="flex-1 bg-white p-7 px-80" >
                    <h2 className="text-lg leading-6 font-medium text-gray-900" style={{ textAlign: 'center' }}>{i18n.t('generate_shipment.view.scan_tote_title')}</h2>
                    <label>{i18n.t('generate_shipment.view.scan_tote_label')}</label>
                    <SearchInput
                        className="sm:col-span-3"
                        ref={inputRefTote}
                        onChange={handleSearchChange}
                        debounce={500}
                        placeholder={i18n.t('generate_shipment.view.scan_tote_placeholder')}
                    ></SearchInput>
                    {showProductScanInput &&
                        <div className="mt-3">
                            <div className="font-bold text-center">{i18n.t('generate_shipment.view.multiple_orders')}<br />{i18n.t('generate_shipment.view.scan_product_title')}</div>
                            <label>{i18n.t('generate_shipment.view.scan_product_label')}</label>
                            <SearchInput
                                className="sm:col-span-3"
                                ref={inputRefProduct}
                                onChange={handleProductSearchChange}
                                debounce={500}
                                placeholder={i18n.t('generate_shipment.view.scan_product_placeholder')}
                            />
                        </div>
                    }
                    {toteSearchIsLoading &&
                        <div className="block text-sm font-medium text-gray-700 mt-2" >Buscando...</div>
                    }
                    {ToteNotFound && !toteSearchIsLoading &&
                        <p className="text-red-600" >{messageToteNotFound}</p>
                    }
                </main>
            </div>
        </PageView>
    )
}
