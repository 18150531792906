import { useTranslation } from "react-i18next";

import { InputField } from "../../../../../components";

const FullNameData = ({shippingAddress, onShippingAddressChange, isEditDisabled}) => {
    const { i18n } = useTranslation();

    return (
        <div className="flex gap-4">
            <InputField
                title={i18n.t('generate_shipment.shipping_address.name')}
                value={shippingAddress.firstName}
                onChange={newValue => onShippingAddressChange({...shippingAddress, firstName: newValue})}
                disabled={isEditDisabled}
                className="w-2/4"
            />
            <InputField
                title={i18n.t('generate_shipment.shipping_address.surname')}
                value={shippingAddress.lastName}
                onChange={newValue => onShippingAddressChange({...shippingAddress, lastName: newValue})}
                disabled={isEditDisabled}
                className="w-2/4"
            />
        </div>
    )
}

export default FullNameData
