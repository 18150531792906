import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const ProductsTotal = ({ productsWithStock, pendingItemsToBeReviewed }) => {
    const { i18n } = useTranslation();

    const totalItemsToBeReviewed = useMemo(() => productsWithStock.reduce((accumulator, { quantity }) => accumulator + quantity, 0), [productsWithStock])

    return (
        <div className='w-full'>
            {pendingItemsToBeReviewed === 0 && (
                <div className='text-green-700 text-lg font-bold p-2 rounded-b-lg pl-4'>
                    {
                        pendingItemsToBeReviewed > 1
                            ? `Total: ${i18n.t('generate_shipment.product_list.products_total.complete_plural')} ${totalItemsToBeReviewed} ${i18n.t('generate_shipment.product_list.products_total.item')}s`
                            : `Total: ${i18n.t('generate_shipment.product_list.products_total.complete_singular')} ${totalItemsToBeReviewed} ${i18n.t('generate_shipment.product_list.products_total.item')}`
                    }
                </div>
            )}

            {pendingItemsToBeReviewed > 0 && (
                <div className='text-yellow-700 text-lg font-bold p-2 rounded-b-lg pl-4'>
                    {`Total: ${pendingItemsToBeReviewed} ${pendingItemsToBeReviewed > 1
                        ? `${i18n.t('generate_shipment.product_list.products_total.item')}s ${i18n.t('generate_shipment.product_list.products_total.pending_plural')}`
                        : `${i18n.t('generate_shipment.product_list.products_total.item')} ${i18n.t('generate_shipment.product_list.products_total.pending_singular')}`}`}
                </div>
            )}
        </div>
    )
}

export default ProductsTotal
