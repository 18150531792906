import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SelectInputShippingMethods } from "../../../../../components";

const ShippingMethodSelect = ({ initialShippingMethod, shippingMethodOptions, onShippingMethodChange = (newShippingMethod) => { }, isShippingMethodEditable = false, value, disabled = false }) => {
    const { i18n } = useTranslation()
    const [shippingMethod, setShippingMethod] = useState(initialShippingMethod)

    const handleShippingMethodChange = (newShippingMethod) => {
        setShippingMethod(newShippingMethod)
        onShippingMethodChange(newShippingMethod)
    }

    useEffect(() => {
        setShippingMethod(value)
    }, [value])

    return (
        <div className="p-4 border-t flex justify-between text-lg">
            <span className="font-medium flex items-center">{i18n.t('generate_shipment.shipping_package_data.shipping_method')}</span>

            {isShippingMethodEditable ? (
                <SelectInputShippingMethods
                    options={shippingMethodOptions}
                    selectedInitial={initialShippingMethod}
                    className="w-10/12"
                    label=""
                    onChange={(newValue) => handleShippingMethodChange(newValue)}
                    disabled={disabled}
                    optionsStyles={"text-xl font-medium"}
                    forceSelected={shippingMethod}
                />
            ) : (
                <span className="flex items-center">{`${shippingMethod?.carrier_name} - ${shippingMethod?.shipping_name} - ${shippingMethod?.country}`}</span>
            )}
        </div>
    )
}

export default ShippingMethodSelect
