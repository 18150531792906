import {PageTopBar, PageView, ConfirmDialog, DialogView} from "../../../components";
import {useParams,useHistory} from "react-router-dom";
import {useState, useRef, useEffect, useContext, useMemo} from "react";
import {UserContext} from "../../../hooks/UserContext";
import {LabelView} from "./LabelView";
import {useQueryClient, useQuery} from "react-query";
import countryList from 'react-select-country-list';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { SearchInput } from "../../../components/SearchInput"
import { ORDERS_PROCESSING} from "../../../navigation/constants"
import { GenerateShipmentFooter, ShippingAddressSection } from './sections'
import { TrashIcon } from "@heroicons/react/outline"
import PackageSelect from './sections/ShippingPackageDataSection/PackageSelect'
import {
    setShippingDetails,
    setShippingAddress,
    orderShippingAddress,
    ordershippingDetails,
} from "../../../redux/createShipmentSlice";
import FullNameData from '../GenerateShipment/sections/ShippingAddressSection/FullNameData'
import { CollapseContainer, InputField } from '../../../../src/components';
import {
    fetchOrders,
    generateGroupOrderLabel,
    fetchOrderShippingPackages,
    fetchStorePackingOptions
} from "../../../services";

const default_store_id = 5050 //94 //2807 //Cuidado con el Perro  is the default store for generating grouped orders store id = 5050

export const ORDERS_TABLE_COLUMNS = [
    {
        Header: <div className="flex justify-center">N° de Pedido</div>,
        accessor: 'order_number'
    },
    {
        Header: <div className="flex justify-center">Estatus</div>,
        accessor: 'status'
    },
    {
        Header: <div className="flex justify-center">Identificador</div>,
        accessor: 'shipping_last_name',
    },
    {
        Header: <div className="flex justify-center"></div>,
        accessor: 'actions',
    }
]
export const GenerateSharedLabel = () => {
    const dispatch = useDispatch()
    const {warehouse_id} = useParams()
    const {user} = useContext(UserContext)
    const history = useHistory()
    const inputRefOrder = useRef()
    const shippingAddress = useSelector(orderShippingAddress)
    const [shippingLabel, setShippingLabel] = useState({})
    const isEditDisabled =  true
    const [errorMessage, setErrorMessage] = useState(null)
    const shippingDetails = useSelector(ordershippingDetails)
    const [grupedOrders, setGrupedOrders] = useState([])
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const [orderSearchIsLoading, setOrderSearchIsLoading] = useState(false)
    const [OrderNotFound, setOrderNotFound] = useState(false)
    const [referenceOrder, setReferenceOrder] = useState(null)
    const [newOrder, setNewOrder] = useState(null)
    const options = useMemo(() => countryList().getData(), [])
    const [textToSearch, setTextToSearch] = useState("")
    const [messageOrderNotFound, setMessageOrderNotFound] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openDialogDocument, setOpenDialogDocument] = useState(false)
    const [isSelectedPackage, setIsSelectedPackage] = useState(false)
    const {i18n} = useTranslation()

    const focusOrderInput = () => {
        const focusTimer = setTimeout(() => {
        inputRefOrder.current.focus()
        }, 50)
        return () => clearTimeout(focusTimer)
    }

    useEffect(() => {
        if (referenceOrder && newOrder){
            console.log(referenceOrder)
            let index = options.findIndex(country => (country.label == referenceOrder.shipping.country || country.value == referenceOrder.shipping.country));
            dispatch(setShippingAddress({
                    firstName: referenceOrder.shipping.first_name || "",
                    lastName: referenceOrder.shipping.last_name || "",
                    phone: referenceOrder.shipping.phone || "",
                    email: referenceOrder.shipping.email || "",
                    address: referenceOrder.shipping.address_1 || "",
                    address2: referenceOrder.shipping.address_2 || "",
                    country: options[index] || {id: "MXN", name: 'MXN'},
                    city: referenceOrder.shipping.city || "",
                    province: referenceOrder.shipping.state || "",
                    zipCode: referenceOrder.shipping.zip_code || "",
                }))

            let existOrder = grupedOrders.find((gruped_order) => gruped_order.id === newOrder.id)
            if (existOrder) return
            if (newOrder.shipping_last_name == referenceOrder?.shipping_last_name) { // exclusive for client Cuidafo con el Perro
                setGrupedOrders((prevOrders) => [...prevOrders, newOrder]);
            }else{
                setErrorMessage("Este pedido "+ newOrder.order_number +" no pertenece al grupo de ordenes." )
                return
            }
            setErrorMessage(null)
        }
      }, [referenceOrder, newOrder]);

      useEffect(() => {
       
        if (grupedOrders.length == 0 && referenceOrder!=null){
            setReferenceOrder(null)
        }
      }, [grupedOrders]);

    const shippingPackages = useQuery(['order_shipping_packages', referenceOrder?.id], () => fetchOrderShippingPackages(referenceOrder.id), {enabled: !!referenceOrder?.id})
    const {
        isLoading: isLoadingPackingOptions,
        isError: isErrorPackingOptions,
        error: packingOptionsError,
        data: packingOptions
    } = useQuery(['packing_options', default_store_id], () => fetchStorePackingOptions(default_store_id))

    const resetSearch = async () => {
        setOrderNotFound(false)
        setErrorMessage(null)
        setTextToSearch("")
      }
    const removeOrders = (id) => {
        setGrupedOrders(grupedOrders.filter((order) => (order.id != id)))
        
    }

    const handleGenerateLabel = async () => {
        if(grupedOrders.length == 0){
            setErrorMessage("Agrega ordenes para generar guía compartida")
            return
        }
        if(!shippingDetails?.packages[0].id){
            setErrorMessage("Por favor seleccione el empaque correspondiente")
            return
        }
        if(grupedOrders.length == 0){
            setErrorMessage("Agrega ordenes para generar guía compartida")
            return
        }
        setOpenConfirmDialog(true)
        setErrorMessage(null)
    }

    const handleSearchChange = async (value) => {
        setOrderNotFound(false)
        setOrderSearchIsLoading(true)
        let order = null
        if ( value === "") return
        let params = {
            store_id: default_store_id,
            order_number: value,
            status: "shipped"
        }
        try {
            let response = await fetchOrders(params)
            if (!response.orders.length){
                inputRefOrder.current?.resetInput()
                setMessageOrderNotFound("Order no encontrada")
                setOrderSearchIsLoading(false)
                setOrderNotFound(true)
                return
            } 
            order = response.orders[0]
            setNewOrder(order)
            if(referenceOrder ==  null || grupedOrders.length == 0){
                setReferenceOrder(order)
            }
        } catch (error) {
            if (error.response.status === 404) {
                setOrderNotFound(true)
                setMessageOrderNotFound("Order no válida")
                setOrderSearchIsLoading(false)
                return
            }
            console.log(error)
            setOrderSearchIsLoading(false)
            window.alert('Ha ocurrido un error inesperado.')
            return
        }
        inputRefOrder.current?.resetInput()
        setOrderSearchIsLoading(false)
       
        
    }
    const [shippingDocuments, setShippingDocuments] = useState([])
    const [printInformation, setPrintInformation] = useState(false)
    const shippingDocumentsToBePrinted = () => shippingDocuments.filter(shippingDocument => !shippingDocument.print)
    const printShippingDocument = function (documentToPrint) {
        const shipping_documents = shippingDocuments.map(sd => (sd.id === documentToPrint.id ? {...sd, print:true} : sd))
        setShippingDocuments([...shipping_documents])

        print("Imprimir Documentos de Envío",false, documentToPrint.file, documentToPrint.printing_type)
    }
    const print = (title, path, type) => {
    
        setOpenDialogDocument(false)
        setPrintInformation({
            ...printInformation,
            "title": title, 
            "type": type,
            "file_path": path
        })
        setOpenDialogDocument(true)
    }
    const handleClosePreview = () => {
        setTimeout(() => {
            history.push(ORDERS_PROCESSING.replace(':warehouse_id', warehouse_id))
            //history.goBack()
        }, 500);
    };
    const onConfirmGenerateLabelHandler = async () => {
        setConfirmLoading(true)
        try {
            const response = await generateGroupOrderLabel(
                referenceOrder.id,
                default_store_id,
                grupedOrders,
                shippingDetails,
                shippingAddress
                )
            setShippingLabel(response.shipping_label)
            setTimeout(() => {
                setOpenConfirmDialog(false)
                setConfirmLoading(false)

                const nonPrintedDocuments = shippingDocumentsToBePrinted();

                if (response.shipping_label)
                    print("Imprimir Guía", response.shipping_label.label_file.path,"label")
                else if(nonPrintedDocuments.length)
                    printShippingDocument(nonPrintedDocuments[0])

                setLoading(false)
            }, 500);
        } catch (e) {
            console.log(e)
            setErrorMessage(e?.response?.data?.error)
            setOpenConfirmDialog(false)
            setConfirmLoading(false)
            setLoading(false)
        }
    }

    return (
        <PageView 
            topMenu={<PageTopBar>
                <div className="text-lg font-semibold"><span className="text-gray-400">{i18n.t('generate_shared_label.generate_label')}</span> </div>
            </PageTopBar>}
            childrenFullWidth={true}
            topMenuFullWidth={true}
            bottomMenuStyles={"border-t-2"}
            errorMessage={errorMessage}
            bottomMenu={
                <GenerateShipmentFooter
                    onSave={handleGenerateLabel}
                    onCancel={() => history.goBack()}
                    primaryButtonLabel={i18n.t('generate_shared_label.generate_label')}
                    isSaveDisabled={!isSelectedPackage}
                />
            }
        >
            <ConfirmDialog
                        open={openConfirmDialog}
                        setOpen={setOpenConfirmDialog}
                        title={i18n.t('generate_shared_label.generate_label')}
                        description={i18n.t('generate_shared_label.generate_label_confirm')}
                        confirmLabel={i18n.t('generate_shared_label.yes')}
                        onConfirm={() => {
                            onConfirmGenerateLabelHandler()
                        }}
                        loading={confirmLoading}
                    />
                    <DialogView setOpen={setOpenDialogDocument} open={openDialogDocument}>
                        <LabelView

                            title={printInformation.title}
                            type={printInformation.type}
                            file_path = {printInformation.file_path} //{shipment.orderId}
                            onCancel={() =>{ 
                                if (printInformation.type == 'document') 
                                    setOpenDialogDocument(false)
                                else 
                                    handleClosePreview(false)
                                }}
                                
                            onPrint={() => {
                                setOpenDialogDocument(false)

                                const nonPrintedDocuments = shippingDocumentsToBePrinted();

                                if (shippingDetails.shippingMethod?.shipping_type == "international" && shippingLabel.billing_file && !shippingLabel.billing_file.print) {
                                    shippingLabel.billing_file.print = true
                                    print(i18n.t('generate_shared_label.print_invoice'),false,shippingLabel.billing_file, "billing"  )
                                } else if(nonPrintedDocuments.length) {
                                    printShippingDocument(nonPrintedDocuments[0])
                                }else if(shippingLabel.invoice_label_file && shippingLabel.invoice_label_file.path && !shippingLabel.invoice_label_file.print){
                                    shippingLabel.invoice_label_file.print = true
                                    print(i18n.t('generate_shared_label.print_invoice_brazil'),false,shippingLabel.invoice_label_file, "label"  )
                                }else if(!shippingLabel.billing_file && referenceOrder.shipping_labels[0].billing_file && !referenceOrder.shipping_labels[0].billing_file.print ){
                                    referenceOrder.shipping_labels[0].billing_file.print = true
                                    print(i18n.t('generate_shared_label.print_invoice'),false,referenceOrder.shipping_labels[0].billing_file, "billing")
                                }else {
                                    handleClosePreview(false)
                                }
                               
                            }
                            }
                        />
                    </DialogView>
                    <div className="flex-1 flex items-stretch gap-4">
                        <main className="flex-1 flex flex-col gap-4">
                            {(referenceOrder ) &&
                                <>
                                   <section>
                                        <CollapseContainer
                                            defaultCollapsed={true}
                                            headerComponent={
                                                <h2 className="mb-0 text-2xl font-bold">{i18n.t('generate_shared_label.order_details')}</h2>
                                            }
                                            onCollapsedText={i18n.t('generate_shared_label.on_collapsed_text')}
                                            onNotCollapsedText={i18n.t('generate_shared_label.on_not_collapsed_text')}
                                        >
                                            <div className='pt-4 flex flex-col gap-4'>
                                                <FullNameData
                                                    shippingAddress={shippingAddress}
                                                    onShippingAddressChange= {updatedShippingAddress => dispatch( setShippingAddress(updatedShippingAddress) )}
                                                    isEditDisabled={isEditDisabled}
                                                />

                                                <InputField
                                                    title={i18n.t('generate_shared_label.address')}
                                                    value={shippingAddress.address}
                                                    onChange={newValue =>  dispatch(setShippingAddress({...shippingAddress, address: newValue}))}
                                                    disabled={isEditDisabled}
                                                    className="w-full"
                                                />
                                                <div className="flex gap-4">

                                                    <InputField
                                                        title={i18n.t('generate_shared_label.address_2')}
                                                        value={shippingAddress.address2}
                                                        onChange={newValue =>  dispatch(setShippingAddress({...shippingAddress, address2: newValue}))}
                                                        disabled={isEditDisabled}
                                                        className="w-full"
                                                    />
                                                    <InputField
                                                        title={i18n.t('generate_shared_label.city')}
                                                        value={shippingAddress.city}
                                                        onChange={newValue =>  dispatch(setShippingAddress({...shippingAddress, city: newValue}))}
                                                        disabled={isEditDisabled}
                                                        className="w-full"
                                                    />
                                                </div>
                                                
                                                <div className="flex gap-4">
                                                    <InputField
                                                        title={i18n.t('generate_shared_label.province')}
                                                        value={shippingAddress.province}
                                                        onChange={newValue =>  dispatch(setShippingAddress({...shippingAddress, province: newValue}))}
                                                        disabled={isEditDisabled}
                                                        className="w-2/4"
                                                    />

                                                    <InputField
                                                        title={i18n.t('generate_shared_label.zip_code')}
                                                        value={shippingAddress.zipCode}
                                                        onChange={newValue => dispatch(setShippingAddress({...shippingAddress, zipCode: newValue}))}
                                                        disabled={false}
                                                        className="w-2/4"
                                                    />
                                                </div>
                                                
                                            </div>
                                        </CollapseContainer>
                                    </section>
                                </> 
                            }
                            <div className="flex-1 flex items-stretch overflow-hidden rounded-lg">

                                <main className="flex-1 bg-white" >
                                    <div className='p-3 flex justify-between'>
                                        <div>
                                            <div className='flex p-1 gap-2'>
                                                <h2 className='mb-0 text-2xl font-bold'>{i18n.t('generate_shared_label.grouped_orders')}</h2>
                                            </div>
                                            <span className='mb-0 text-lg'>{i18n.t('generate_shared_label.scan_all_orders')}</span>
                                            {/* {pendingItemsToBeReviewed === 0 && <span className='mb-0 text-lg text-green-400 font-medium'>{'¡El pedido está completo!'}</span>} */}
                                        </div>
                                        <div className="flex flex-col justify-center w-2/5">
                                    
                                            <SearchInput 
                                            debounce="50" 
                                            placeholder = {i18n.t('generate_shared_label.scan_order_placeholder')}
                                            dependencies={[grupedOrders]} 
                                            inputValue={textToSearch} 
                                            className="w-full my-2" 
                                            onChange={handleSearchChange} 
                                            onReset={() => {resetSearch()}} 
                                            ref={inputRefOrder}/>
                                            { OrderNotFound && !orderSearchIsLoading &&
                                                <p className="text-red-600" >{messageOrderNotFound}</p>
                                            }
                                        </div>
                                    </div>
                                    <table className="w-full ">
                        <thead>
                            <tr>
                                {ORDERS_TABLE_COLUMNS.map(column => (
                                    <th
                                        className="px-6 py-3 font-medium text-gray-500 bg-white tracking-wider border-t"
                                        key={column.accessor}
                                    >
                                        {column['Header']}
                                    </th>
                                ))}
                            </tr>
                        </thead>

                        <tbody>
                            {grupedOrders?.map((order) => {
                                return (
                                    <tr
                                        key={`OrdersTable-${order.id}`}
                                        className=" bg-gray-50"
                                    >
                                        <td className="text-center p-2 whitespace-nowrap border-t">
                                            <span className='rounded-lg  select-none px-2.5 py-0.5'>
                                            #{order.order_number}
                                            </span>
                                        </td>

                                        <td className="text-center p-2 whitespace-nowrap border-t">
                                            <span className='rounded-lg bg-green-100 text-green-800 select-none px-2.5 py-0.5'>
                                                {order.status}
                                            </span>
                                        </td>

                                        <td className="text-center p-2 whitespace-nowrap border-t">
                                            {order.shipping_last_name}
                                        </td>
                                        <td className="text-center p-2 whitespace-nowrap border-t">

                                        <div  onClick={() => removeOrders(order.id)}
                                            className="color-red cursor-pointer justify-self-center">
                                            <TrashIcon className="h-5 w-5 text-red-500" />
                                        </div>
                                        </td>
                                    </tr>
                            )})}
                        </tbody>
                    </table>
                                </main>
                            </div>
                        </main>
                        <aside className="w-5/12 border-gray-200 flex flex-col gap-4">
                            {(true ) &&
                                <>
                                    <section className="bg-white rounded-lg">
                                        <div className="p-4">
                                            <h2 className="mb-0 text-2xl font-bold">{i18n.t('generate_shared_label.used_package')}</h2>
                                            <p className="mb-0 text-lg">{i18n.t('generate_shared_label.register_package_shipping')}</p>
                                        </div>
                                        <div className="border-t p-4">
                                            <PackageSelect
                                            title={i18n.t('generate_shared_label.package')}
                                                isPackagelessOrder={false}
                                                isFragileOrder={false}
                                                storePackingOptions={packingOptions?.store_packing_options}
                                                availableShippingPackages={shippingPackages?.data}
                                                onShippingPackageChange={(newValue) => {
                                                    setIsSelectedPackage(newValue.id > 0 ? true : false)

                                                    dispatch(
                                                        setShippingDetails({
                                                            ...shippingDetails,
                                                            packages: [newValue],
                                                            package_id: newValue.id,
                                                            description: newValue?.name,
                                                            shippingMethod: null
                                                        })
                                                    )
                                                }}
                                            />

                                        </div>

                                    </section>
                                </>
                            }
                        </aside>
                        
                    </div>
        </PageView>
    )
}