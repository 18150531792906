import { useTranslation } from "react-i18next";

import { InputQuantity } from "../../../../../components";

const PackageQuantitySelect = ({ initialPackageQuantity, onUpdateQuantity = (newQuantity) => { } }) => {
    const { i18n } = useTranslation();

    return (
        <div className="p-4 border-t flex justify-between">
            <span className="font-medium text-lg flex items-center">{i18n.t('generate_shipment.shipping_package_data.package')}s</span>
            <InputQuantity
                initialValue={initialPackageQuantity}
                updateData={onUpdateQuantity}
            />
        </div>
    )
}

export default PackageQuantitySelect
