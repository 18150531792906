import { useTranslation } from "react-i18next";
import { SearchInput } from "../../../../../components";

const ProductSearch = ({alertMsg, search, inputRef, onSearchChange = (value) => {}, dependencies}) => {
    const { i18n } = useTranslation()

    return (
        <div className="flex flex-col justify-center w-2/5">
            <SearchInput
                inputValue={search}
                selectedInitial={search}
                placeholder = {i18n.t('generate_shipment.product_list.product_search.placeholder')}
                hideIcons = {true}
                ref={inputRef}
                debounce= {250}
                onChange={onSearchChange}
                onPaste={(e) => {e.preventDefault()}}
                dependencies= {[dependencies]}
                disabledSearchOnChange={true}
            />

            {alertMsg && <p className="mt-1 text-sm text-red-600">{alertMsg}</p>}
        </div>
    )
}

export default ProductSearch
