import { useTranslation } from "react-i18next";
import { Button } from "../../../../components";

const MissingPickingPiecesSection = ({ onSubmitOrderForReview, isSubmitOrderForReviewDisabled, isHidden = false }) => {
    const { i18n } = useTranslation();

    if (isHidden) return (<></>)

    return (
        <section className="bg-white rounded-lg p-4 flex flex-col gap-2 text-lg">
            <h2 className="mb-0 text-2xl font-bold">{i18n.t('generate_shipment.missing_picking_pieces.title')}</h2>
            <p className="mb-0">
                <span className="font-bold">{i18n.t('generate_shipment.missing_picking_pieces.scan_all_products')}</span>
                {i18n.t('generate_shipment.missing_picking_pieces.missing_items')}
            </p>
            <Button
                className="w-full"
                onClick={onSubmitOrderForReview}
                disabled={isSubmitOrderForReviewDisabled}
            >
                {i18n.t('generate_shipment.missing_picking_pieces.action')}
            </Button>
        </section>
    )
}

export default MissingPickingPiecesSection
