import { useTranslation } from "react-i18next";
import { DamagedIcon } from "../../../../components/Icons/DamagedIcon";
import { FragileInformationCard } from "../../../../components";
import { PACKING_MATERIAL_TYPES } from "../../constants";
import { useEffect, useState } from "react";

const FragileNotificationSection = ({isFragileOrder, warehousePackingMaterials, storePackingMaterials}) => {
    const {i18n} = useTranslation();

    const [fragilePackingMaterial, setFragilePackingMaterial] = useState(null)

    useEffect(() => {
        const preferredPackingMaterials = storePackingMaterials?.map(({warehouse_packing_material}) => (
            warehouse_packing_material
        )).filter(material => (
            material.for_fragile === isFragileOrder && material.material_type === PACKING_MATERIAL_TYPES.PROTECTION_MATERIAL
        ))

        const defaultPackingMaterials = warehousePackingMaterials?.filter(material => (
            material.for_fragile === isFragileOrder && material.material_type === PACKING_MATERIAL_TYPES.PROTECTION_MATERIAL && material.is_default
        )).filter(material => (
            !preferredPackingMaterials.some(({material_type}) => material_type === material.material_type)
        ))

        if(preferredPackingMaterials?.length > 1 || (preferredPackingMaterials?.length !== 1 && defaultPackingMaterials?.length > 1)){
            throw new Error("Unsupported multiple preferred fragile packing materials")
        }

        const fragilePackingMaterial = preferredPackingMaterials?.length === 1 ? preferredPackingMaterials[0] : defaultPackingMaterials?.length === 1 ? defaultPackingMaterials[0] : null

        setFragilePackingMaterial(fragilePackingMaterial)
    }, [isFragileOrder, warehousePackingMaterials, storePackingMaterials])

    const showFragileNotification = isFragileOrder || fragilePackingMaterial ? true : false

    return (
        <FragileInformationCard
            title={i18n.t('generate_shipment.fragile_notification.title')}
            description={i18n.t('generate_shipment.fragile_notification.description')}
            iconComponent={<DamagedIcon className="h-full" />}
            containerStyles={"bg-red-100 text-red-600"}
            isHidden={!showFragileNotification}
            fragilePackingMaterial={fragilePackingMaterial}
        />
    )
}

export default FragileNotificationSection
