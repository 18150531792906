import { useState, useContext, useRef, useMemo } from "react"
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory, useParams } from "react-router-dom"
import { useQuery } from "react-query"

import {
  ActionMenu,
  BasicTable,
  FormattedDate,
  PageTopBar,
  PageView,
  SlidePanel,
  NoticeBanner
} from "../../components"
import { SearchInput } from "../../components/SearchInput"

import {
  RECEIVE_RETURN,
  RETURNS,
  SHOW_ORDER_RETURNS,
  CREATE_RETURN,
  SEARCH_RETURN,
  RECEIVE_RETURNED_ORDER,
  SHOW_RETURN,
} from "../../navigation/constants"
import { StatusHighlighted } from '../../components/NewTable/StatusHighlighted';
import { RETURNS_STATUSES} from '../../components/NewTable/StatusHighlightedDefinitions';
import { RETURNS_STATUSES_SELECT} from '../../components/BasicTable/StatusPillDefinitions';
import { Loader } from "../../components/Loader"
import { SelectColumnFilter } from "../../components/BasicTable/modules/ColumnFiltersUI"
import { UserContext } from "../../hooks/UserContext"
import { fetchReturns } from "../../services/returnServices"
import { findOrderReturn } from "../../services/orderServices"
import {fetchArrivedReturn } from "../../services"
import { fetchReturnsSummary } from "../../services/warehouseSevices"
import { ShowOrderContainer } from "./../Orders/ShowOrderContainer"
import { ShowReturnContainer } from "./ShowReturnContainer"
import { getReturnTypeDefinition, RETURN_TYPES } from "./ReturnTypes"
import { ReceiveReturnContainer } from "./ReceiveReturnContainer"
import { getReturnNewOrderLabel } from "./ReturnNewOrderLabels"
import { setReturnsListPageSize } from '../../redux/pageSizeSlice';
import { useDispatch, useSelector } from "react-redux"
import { StatusPill } from "../../components/BasicTable/StatusPill"

export const ListReturnContainer = () => {
  const { i18n } = useTranslation();
  const { user } = useContext(UserContext)
  const history = useHistory()
  const [openError, setOpenError] = useState(false)

  const {warehouse_id} = useParams()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const pageSize = useSelector((state) => state.pageSize.returnsList)
  const [searchParams, setSearchParams] = useState({
    page: 1,
    per_page: pageSize,
    warehouse_id: warehouse_id
  })
  
  const ShowReturnSlidePanelRef = useRef(null)
  const ReceiveReturnSlidePanelRef = useRef(null)
  
  const searchinputRef = useRef()
  const tableRef = useRef()
  const permissions = useMemo(() => {
    return {
      showListReturns: user ? user.permissions.index_returns: false,
    }
  }, [user])

  const columns = [
    {
      Header: i18n.t('list_returns.headers.store'),
      accessor: "store", // accessor is the "key" in the data
      searchAs: "store",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: i18n.t('list_returns.headers.returned_order_number'),
      accessor: "returned_order_number", // accessor is the "key" in the data
      searchAs: "returned_order_number",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: i18n.t('list_returns.headers.created_order_number'),
      accessor: "created_order_number", // accessor is the "key" in the data
      searchAs: "created_order_number",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: i18n.t('list_returns.headers.type'),
      accessor: "return_type", // accessor is the "key" in the data
      Filter: SelectColumnFilter,
      selectFilterOptions: RETURN_TYPES.filter(
        (status) => status.filterable !== false
      ).map((status) => {
        return status.filterable === false
          ? null
          : { id: status.type, label: status.label }
      }),
      searchAs: "return_type",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: i18n.t('list_returns.headers.status'),
      accessor: "status", // accessor is the "key" in the data
      Filter: SelectColumnFilter,
      selectFilterOptions: RETURNS_STATUSES_SELECT.filter(
        (status) => status.filterable !== false
      ).map((status) => {
        return status.filterable === false
          ? null
          : { id: status.status, label: status.label }
      }),
      searchAs: "status",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: i18n.t('list_returns.headers.shipping_number'),
      accessor: "shipping_number", // accessor is the "key" in the data
      searchAs: "shipping_number",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: i18n.t('list_returns.headers.created_at'),
      accessor: "created_at", // accessor is the "key" in the data
      disableFilters: true,
      disableSortBy: false,
    },
    {
      Header: "",
      accessor: "actions", // accessor is the "key" in the data
      disableFilters: true,
      disableSortBy: true,
      fetchingIndicator: true,
      shrinkToContent: true,
    },
  ]
  

  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    isPreviousData,
    refetch,
  } = useQuery(
    ["returns_index", searchParams],
    () => fetchReturns(searchParams),
    {
      enabled: user.permissions.index_returns,
      keepPreviousData: true,
    }
  )

  

  const {
    isLoading: isLoadingSummary,
    isError: isErrorSummary,
    errorSummary,  
    data: dataSummary,
    isFetchingSummary,
    isPreviousDataSummary,
    refetch: refetchSummary
} = useQuery(['returns_info', warehouse_id], () => fetchReturnsSummary(warehouse_id))

  const showOrderDetail = (order) => {
    history.push(SHOW_ORDER_RETURNS.replace(":id", order.id).replace(':warehouse_id', warehouse_id))
  }

  const showReturnDetail = (_return) => {
    history.push(SHOW_RETURN.replace(":id", _return.id).replace(':warehouse_id', warehouse_id))
  }

  const handlerOnClickTracking = (_return) => {
    window.open(_return.returned_order?.return_label.tracking_url)
  }

  const onSortChange = (orderBy) => {
    if (orderBy.length > 0) {
      setSearchParams({
        ...searchParams,
        order_by: orderBy[0].id,
        order: orderBy[0].desc ? "desc" : "asc",
      })
    } else {
      if ("order_by" in searchParams) {
        delete searchParams.order_by
        delete searchParams.order
        setSearchParams({ ...searchParams })
      }
    }
  }

  const onFilterChange = (activeFilters) => {
    columns.forEach((col) => {
      if (searchParams[col.searchAs]) {
        delete searchParams[col.searchAs]
      }
    })
    let filters = []
    activeFilters.forEach((filter) => {
      let column = columns.find((col) => col.accessor === filter.id)
      filters[column.searchAs] = filter.value
    })

    tableRef.current.resetPagination()
    setSearchParams({ ...searchParams, ...filters, page: 1 })
    // console.log("searchParams",searchParams)
  }

  const handleSearchChange = async (value) => {
    if (value === "") return
    setLoading(true)
    try {
      const response = await findOrderReturn({scanned_string: value, warehouse_id: warehouse_id})
      const belongs_to_warehouse = response.store.warehouses.find(warehouse => warehouse.id == warehouse_id)
      setLoading(false)
      if (!belongs_to_warehouse){
        console.log(response.store.warehouses[0].name)
        let error = i18n.t('returns.errors.not_belong_to_warehouse', {order_number: response.order_number, warehouse_name: response.store.warehouses[0].name})
        setOpenError({status: true, text: error})
        return
      }
      navigateToReturn(response, value, warehouse_id)
    } catch (e) {
        setLoading(false)
        let error = e.response.data.error
        let error_code = e.response.data.error_code
        switch (error_code) {
          case "ORDER_NOT_FOUND":
            try {
              const response = await fetchArrivedReturn(value)
              if (response) {
                navigateToReturn(response, value, warehouse_id);
              } else {
                history.push(SEARCH_RETURN.replace(":scann_string", value).replace(':warehouse_id', warehouse_id));
              }
            } catch (error) {
              history.push(SEARCH_RETURN.replace(":scann_string", value).replace(':warehouse_id', warehouse_id))
              break;
            }
          case "ORDER_NOT_SHIPPED":
            setOpenError({status: true, text: error})
            break;
          default:
            setOpenError({status: true, text: error})
            break;
        }
    }
  }

  const navigateToReturn = (response, value, warehouse_id) => {
    const { return: returnOrder, has_multi_packages, status, id } = response;
    console.log()
    if (returnOrder) {
      const mustBeReceived = (returnOrder.status === "REQUESTED" || returnOrder.status === "ARRIVED_AT_WAREHOUSE" || 
                              (returnOrder.status === "CANCELLED" && status === 'shipped'));
  
      if (mustBeReceived) {
        history.push(RECEIVE_RETURN.replace(":id", returnOrder.id).replace(':warehouse_id', warehouse_id));
      } else if (has_multi_packages) {
        history.push(CREATE_RETURN.replace(":orderId", id).replace(':warehouse_id', warehouse_id));
      } else if (status === 'returned' || status === 'reentered') {
        history.push(RECEIVE_RETURNED_ORDER.replace(":order_id", id).replace(':warehouse_id', warehouse_id));
      } else {
        history.push(SEARCH_RETURN.replace(":scann_string", value).replace(':warehouse_id', warehouse_id));
      }
    } else if (response.order_number) {
      history.push(CREATE_RETURN.replace(":orderId", id).replace(':warehouse_id', warehouse_id));
    } else {
      history.push(SEARCH_RETURN.replace(":scann_string", value).replace(':warehouse_id', warehouse_id));
    }
  }
  
  const getMenuOptions = (_return) => {
    const options = [
      {
        title: "Ver detalles del retorno",
        clickHandler: () =>
          history.push(SHOW_RETURN.replace(":id", _return.id).replace(':warehouse_id', warehouse_id)),
      }
    ]
    if (_return.returned_order) {
      options.push({
        title: "Ver orden retornada",
        clickHandler: () =>
          history.push(
            SHOW_ORDER_RETURNS.replace(":id", _return.returned_order.id).replace(':warehouse_id', warehouse_id)
          ),
      })
    }
    if ((_return.status === "REQUESTED" || _return.status === "ARRIVED_AT_WAREHOUSE") && _return.return_type != "RETURNED_BY_CARRIER") {
      options.push({
        title: "Recibir orden en devolución",
        clickHandler: () =>
          history.push(
            RECEIVE_RETURN.replace(":id", _return.id).replace(':warehouse_id', warehouse_id)
          ),
      })
    }
    if (_return.return_type === "RETURNED_BY_CARRIER" && (_return.status == "REQUESTED" || _return.status == "ARRIVED_AT_WAREHOUSE")) {
      options.push({
        title: "Recibir retorno por paquetería",
        clickHandler: () =>
          history.push(
            RECEIVE_RETURN.replace(":id", _return.id).replace(':warehouse_id', warehouse_id)
          ),
      })
    }
    if (_return.created_order) {
      options.push({
        title: "Ver orden creada",
        clickHandler: () =>
          history.push(
            SHOW_ORDER_RETURNS.replace(":id", _return.created_order.id).replace(':warehouse_id', warehouse_id)
          ),
      })
    }
    return options
  }

  const getTableData = () => {
    return data.returns.map((_return) => {
      return {
        returned_order_number: (
          <div
            className="hover:underline cursor-pointer "
            onClick={() => showOrderDetail(_return.returned_order)}
          >
               {_return.returned_order?.order_number ? (
              <>
              #{String(_return.returned_order?.order_number)}
              </>)
              :
              (<>
                 <i>{i18n.t('returns.unidentified')}</i>
                </>)
              }
          </div>
        ),
        created_order_number: _return.created_order ? (
          <div
            className="hover:underline cursor-pointer "
            onClick={() => showOrderDetail(_return.created_order)}
          >
            #{String(_return.created_order.order_number)}
          </div>
        ) : (
          <div><i>{getReturnNewOrderLabel(_return.return_type, _return.status)}</i></div>
        ),
        return_type: <div>{getReturnTypeDefinition(_return.return_type).label}</div>,
        status: (
          <StatusHighlighted
            className="cursor-pointer"
            status={_return.status}
            statuses={RETURNS_STATUSES}
          />
        ),
        shipping_number:  (
          <div
          className="font-style: italic "
          >

          <div> {
            
              (_return.returned_order?.return_label === null) ?
              (_return.return_type ===  'RETURNED_BY_CARRIER') ? 
              'No aplica' : 'Guía externa' 
              : 
              <>
              { _return?.returned_order?.return_label?.shipping_number ? (
                
                <a className="not-italic" onClick={() => handlerOnClickTracking(_return)}>
                  {String(_return.returned_order?.return_label?.shipping_number )}
                </a>             
              ): _return?.arrived_return_label?.scanned_return_label ?(
                
                <a className="not-italic" onClick={() => {}}>
                  {_return?.arrived_return_label?.scanned_return_label}
                </a> 
              ):(
                  
                  <i>Guía externa</i>
              )

              }
              </>
            }</div>
          </div>
        ),
        created_at: (
          <FormattedDate date={_return.created_at} shortDate={true} />
        ),
        store: <div>{_return.returned_order?.store.name || _return.store.name}</div>,
        actions: (
          <ActionMenu className="float-right" items={getMenuOptions(_return)} />
        ),
      }
    })
  }

  return (
    <>
      <PageView
        topMenu={
          <PageTopBar>
            <div className="text-lg font-semibold">
              Retornos
            </div>
          </PageTopBar>
        }
        childrenFullWidth={true}
        topMenuFullWidth={true}
      >
        { permissions.showListReturns && (
          <div className="flex flex-col">
            <nav className='flex relative rounded-lg border bg-white py-2 mb-2 text-gray-700'>

                        <div className="flex flex-col lg:flex-row justify-start border-l-2 lg:border-0 ml-5 lg:ml-0">
                          <div className="lg:max-w-min ml-5 my-1 lg:my-2 text-base leading-4 py-2 pl-0">
                          {i18n.t('list_returns.require_monitoring')}
                          </div>
                          <div className="max-w-min flex flex-col static ml-5 my-1 lg:my-2">
                              <button className="text-left whitespace-nowrap border border-gray-300 shadow-sm rounded-lg py-3 px-3 text-gray-700 font-medium hover:bg-gray-50 outline-none"
                                   onClick={() => onFilterChange( [{id: 'status', value: 'ARRIVED_AT_WAREHOUSE'}])}>
                                      <StatusHighlighted status="arrived_at_warehouse" statuses={[{
                                          status: "arrived_at_warehouse",
                                          label: "statuses.returns_statuses.arrived_at_warehouse",
                                          classes: "bg-pink-50 text-pink-500 border-pink-50"
                                      }]}/> 
                                        <span className='ml-4 text-base font-bold'>{dataSummary?.analytics_data?.arrived_returns || 0} </span> <span className='lowercase font-normal text-base'>Pedidos</span>
                              </button>
                          </div>
                          <div className="max-w-min flex flex-col static ml-5 my-1 lg:my-2">
                              <button className="text-left whitespace-nowrap border border-gray-300 shadow-sm rounded-lg py-3 px-3 text-gray-700 font-medium hover:bg-gray-50 outline-none"
                                   onClick={() => onFilterChange( [{id: 'status', value: 'REQUESTED'}])}>
                                      <StatusHighlighted status="pending" statuses={[{
                                          status: "pending",
                                          label: "statuses.returns_statuses.requested",
                                          classes: "bg-blue-50 text-blue-500 border-blue-50"
                                      }]}/> 
                                        <span className='ml-4 text-base font-bold'>{dataSummary?.analytics_data?.requested_returns || 0} </span> <span className='lowercase font-normal text-base'>Pedidos</span>
                              </button>
                          </div>
                        </div>
            </nav>
          </div>
          )}

          <div className="flex flex-col">
            <nav className='flex flex-col relative rounded-lg border bg-white p-10 mb-2 text-gray-700'>
              <div className="text-lg font-semibold pb-2 text-center">
                {i18n.t('list_returns.scan_package_title')}
              </div>
              <div className="flex-full">
                <SearchInput
                    className="sm:col-span-3"
                    ref = {searchinputRef}
                    onChange = {handleSearchChange}
                    debounce = {500}
                    placeholder = {i18n.t('list_returns.scan_package_placeholder')}
                ></SearchInput>
                 <div className='mt-1'>
                    {openError.status &&
                        <NoticeBanner text={openError.text} className="mb-2"/>
                    }
                 </div>
              </div>
            </nav>
          </div>
        {(!isPreviousData && isLoading ) || loading ? (
          <>
            <Loader show={true}></Loader>
          </>
        ) : isError ? (
          <>Error: {error.message}</>
        ) : permissions.showListReturns ? (
          
          <BasicTable
            showHeader
            showLoader={isFetching && isPreviousData}
            columns={columns}
            filterable
            sortable
            showPaginator
            data={getTableData()}
            onFilterChange={onFilterChange}
            onSortChange={onSortChange}
            paginationMeta={data?.meta}
            showCount={true}
            labelToPaginator={i18n.t('list_returns.returns')}
            pagesSize = {searchParams.per_page}
            onPaginationChange= { (requestedPage) => { setSearchParams({...searchParams, page: requestedPage}) } }
            onPageSizeChange= { (pageSize) => {
                setSearchParams({...searchParams, per_page: pageSize, page: 1})
                dispatch(setReturnsListPageSize(pageSize))
                tableRef.current.resetPagination()
            }}
            showDataStatus
            isFetching={isFetching}
            emptyTableText={i18n.t('list_returns.empty_table_text')}
            ref={tableRef}
          />
        ) : (
          <></>
        )}
        
      </PageView>
      <Switch>
        <Route exact path={SHOW_ORDER_RETURNS}>
          <SlidePanel title="Ver Orden" referrer={RETURNS.replace(':warehouse_id', warehouse_id)}>
            <ShowOrderContainer />
          </SlidePanel>
        </Route>
        <Route exact path={SHOW_RETURN}>
          <SlidePanel
            title={i18n.t('list_returns.return_details')}
            referrer={RETURNS.replace(':warehouse_id', warehouse_id)}
            ref={ShowReturnSlidePanelRef}
            onClose={() => refetch()}
          >
            <ShowReturnContainer
            //   onCancel={() => ShowReturnSlidePanelRef.current.closePanel()}

            />
          </SlidePanel>
        </Route>
      </Switch>
     
    </>
  )
}