import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { pt } from "./pt"
import { es } from "./es"

const userLang = navigator.language || navigator.userLanguage
const defaultLang = userLang.substr(0,2)
console.log(`"${defaultLang}"`);

i18next.use(initReactI18next)
i18next.init({
    interpolation: {
        escapeValue: false
    },
    lng: defaultLang,
    fallbackLng: 'es',
    resources: {
        pt: {
            translation: pt
        },
        es: {
            translation: es
        }
    }
})

export default i18next
